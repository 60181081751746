import { FunctionComponent, useEffect, useState } from 'react';
import CustomHead from '@xFrame4/components/next/CustomHead';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Layout from '@components/layout/Layout';
import { PageProps } from './Page';

interface HomePageProps extends PageProps
{

}

const HomePage: FunctionComponent<HomePageProps> = (props) =>
{
    const t = useTranslate();

    /** Render */
    return (
        <>
            <CustomHead
                metaData={{
                    title: t('APP_TITLE'),
                    metaDescription: t('HOME_META_DESC')
                }}
            />

            <Layout>
                <>
                {t('HOME_PAGE')}
                </>
            </Layout>
        </>
    );
}

export default HomePage;