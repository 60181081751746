import { FunctionComponent, useEffect } from 'react';
import { GetStaticProps } from 'next';
import config from '@config';
import CustomHead, { HeadMetaData } from '@xFrame4/components/next/CustomHead';
import { useTranslate } from '@xFrame4/components/Hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { useAppActionDispatch } from '@redux/hooks';
import { RouteProps, getCommonStaticProps } from '@components/pages/NextJsPagesCommon';
import HomePage from '@components/pages/HomePage';

interface HomeRouteProps extends RouteProps 
{

}

export const getStaticProps: GetStaticProps<HomeRouteProps> = async (context) => 
{
    // Common page props
    let commonPageProps = await getCommonStaticProps();

    return {
        props: {
           ...commonPageProps,
        }
    };
}

const HomeRoute: FunctionComponent<HomeRouteProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();

    /** Set the global app language with Redux */
    useEffect(() => {
        dispatch(setAppLanguage(config.defaultLanguage))
    }, []);
    
    /** Render */
    return (
        <HomePage 
            {...props}
        />
    );
}

export default HomeRoute;