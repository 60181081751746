import { FunctionComponent } from 'react';

export interface FooterProps
{

}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    /** Render */
    return (
        <>
        </>
    );
}

export default Footer;