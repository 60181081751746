import { FunctionComponent, useEffect, useState } from 'react';
import { useLoad } from '@xFrame4/components/Hooks';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';

interface LayoutProps
{
    children: JSX.Element | JSX.Element[];
}

const Layout: FunctionComponent<LayoutProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);

    /** Render */
    return (
        <>
            <div className="layout-wrapper">
                <Header 
                    
                />
                
                {props.children}
                
                <Footer 
                    
                />
            </div>
        </>
    );
}

Layout.defaultProps = {
    
};

export default Layout;