import { FunctionComponent } from 'react';

export interface HeaderProps
{

}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    /** Render */
    return (
        <>
        </>
    );
}

export default Header;